import React from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Center,
  Stack,
  Text
} from "@chakra-ui/react";
import { PageCardComponent } from "../../core/components/page-card.component";
import { PatientSummaryComponent } from "./patient-summary.component";
import { useTypedSelector } from "hooks/use-typed-selector.hook";
import { RegisterPatientComponent } from "./register-patient.component";
import { env } from "app/config";

interface FoundPatientListComponentProps {}

export const FoundPatientListComponent: React.FC<
  FoundPatientListComponentProps
> = (props: FoundPatientListComponentProps) => {
  const {
    listOfPatientWithDetails: foundPatients,
    verificationState,
    phoneNumber
  } = useTypedSelector((state) => state.patient);

  return (
    <PageCardComponent>
      {verificationState === "verified" ? (
        <RegisterPatientComponent phoneNumber={phoneNumber} />
      ) : (
        <></>
      )}
      {verificationState === "verified" ? (
        foundPatients.length ? (
          foundPatients.map((patient) => {
            return (
              <PatientSummaryComponent
                key={patient.patient.id}
                targetPatient={patient}
                personIdentifiers={patient.personIdentifiers}
              />
            );
          })
        ) : (
          <Stack>
            {env.isAppointment === "true" ? (
              <Stack>
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>
                    এই নম্বর দিয়ে কোনো রেজিস্টার্ড ব্যক্তি পাওয়া যায়নি ।
                  </AlertTitle>
                </Alert>
                <Alert status="warning">
                  <AlertIcon />
                  <AlertTitle>রেজিস্ট্রেশন এর জন্য CMH ভিজিট করুন ।</AlertTitle>
                </Alert>
              </Stack>
            ) : (
              <Center>
                <Text color="gray.300">No patients found</Text>
              </Center>
            )}
          </Stack>
        )
      ) : (
        <></>
      )}
    </PageCardComponent>
  );
};
