import React from "react";
import { Center, Heading, Image, Stack } from "@chakra-ui/react";
import { SearchPatientComponent } from "../components/search-patient.component";
import { FoundPatientListComponent } from "../components/found-patient-list.component";
import { BindulogicCreditsComponent } from "modules/core/components/bindulogic-credits.component";
import { SupportingOrganizationComponent } from "../components/supporting-organization.component";
import { env } from "app/config";
import { useDocumentTitle } from "hooks/use-document-title.hook";

interface BookingPageProps {}

export const BookingPage: React.FC<BookingPageProps> = (
  props: BookingPageProps
) => {
  useDocumentTitle("E-Ticket | " + env.appName);
  return (
    <Center width="100%">
      <Stack width={{ base: "100%", lg: "1200px" }}>
        <Stack justifyContent={"space-between"} minHeight="100vh">
          <Stack spacing="12">
            <Stack pt={12}>
              <Center>
                <Image
                  src={env.organizationLogo}
                  objectFit={"cover"}
                  width="10rem"
                />
              </Center>
              <Center width="100%">
                <Heading fontSize={{ base: "xs", lg: "md" }}>
                  {env.organizationName.toUpperCase()}
                </Heading>
              </Center>
              <Center width="100%">
                <Heading fontSize={{ base: "xs", lg: "md" }}>
                  {env.isAppointment === "true"
                    ? "APPOINTMENT PLATFORM"
                    : "E-TICKETING PLATFORM"}
                </Heading>
              </Center>
              <Center width="100%">
                <Heading fontSize={{ base: "2xl", lg: "6xl" }}>
                  {env.isAppointment === "true"
                    ? "BOOK YOUR APPOINTMENT"
                    : "BOOK YOUR TICKET"}
                </Heading>
              </Center>
            </Stack>
            <Stack>
              <SearchPatientComponent />
              <FoundPatientListComponent />
            </Stack>
          </Stack>

          <Stack spacing="0">
            <SupportingOrganizationComponent />
            <BindulogicCreditsComponent />
          </Stack>
        </Stack>
      </Stack>
    </Center>
  );
};
