import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  Stack,
  useDisclosure,
  useToast,
  Text,
  Heading,
  SimpleGrid,
  StackDivider,
  Tooltip,
  FormErrorMessage
} from "@chakra-ui/react";
import { FaPlus, FaTimes } from "react-icons/fa";
import dayjs from "dayjs";
import {
  useCreatePatientMutation,
  useLazyGetPatientInformationFromNidQuery
} from "../api";
import { useTypedDispatch } from "hooks/use-typed-dispatch.hook";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/chakra-react-datepicker.css";
import { Select } from "chakra-react-select";
import { divisions } from "utils/bbs";
import { genderList } from "../constants/gender.constant";
import { registerNewPatient } from "../state/patient.slice";
import { useTypedSelector } from "hooks/use-typed-selector.hook";
import { religionList } from "../constants/religion.constant";
import { RegexMatcher } from "utils/regex-matcher.util";
import { occupationList } from "../constants/occupation-list.constant";
import { env } from "app/config";

interface RegisterPatientComponentProps {
  phoneNumber: string;
}

export const RegisterPatientComponent: React.FC<
  RegisterPatientComponentProps
> = (props: RegisterPatientComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useTypedDispatch();
  const patientState = useTypedSelector((state) => state.patient);
  const [getPatientInformationFromNid, getPatientInformationFromNidResult] =
    useLazyGetPatientInformationFromNidQuery();
  const toast = useToast();

  const [createPatient, createPatientResult] = useCreatePatientMutation();

  const [name, setName] = useState<string>("");

  const [addressLine, setAddressLine] = useState<string>();

  const [nationalIdNumber, setNationalIdNumber] = useState<string>("");
  const [passportNumber, setPassportNumber] = useState<string>();
  const [healthIdNumber, setHealthIdNumber] = useState<string>();

  const [birthCertificate, setBirthCertificate] = useState<string>();
  const [occupation, setOccupation] = useState<{
    label: string;
    value: string;
  }>();
  const [nationality, setNationality] = useState<string>();

  const [gender, setGender] = useState<{
    label: string;
    value: string;
  }>();

  const [division, setDivision] = useState<{
    label: string;
    code: number;
    value: number;
  }>();

  const [district, setDistrict] = useState<{
    label: string;
    code: number;
    value: number;
  }>();

  const [subdistrict, setSubdistrict] = useState<{
    label: string;
    code: number;
    value: number;
  }>();

  const [union, setUnion] = useState<{
    label: string;
    code: number;
    value: number;
  }>();
  const [religion, setReligion] = useState<{
    label: string;
    value: string;
  }>();
  // Show default as 25 years old
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);

  const [isDateOfBirthEstimated, setIsDateOfBirthEstimated] =
    useState<boolean>(false);

  const [age, setAge] = useState<number | null>(null);

  useEffect(() => {
    isNidValid();
  }, [nationalIdNumber]);

  const isNidValid = () => {
    return (
      nationalIdNumber === "" ||
      RegexMatcher.match(nationalIdNumber, RegexMatcher.nationalIdentification)
    );
  };

  useEffect(() => {
    if (
      getPatientInformationFromNidResult.isSuccess &&
      getPatientInformationFromNidResult.data.patient
    ) {
      const { person, personIdentifiers, addresses } =
        getPatientInformationFromNidResult.data.patient;
      setName(
        person.name
          .replace("undefined", "")
          .trim()
          .toUpperCase()
          .replace("UNDEFINED", "")
      );
      setGender({
        label: person.gender,
        value: person.gender
      });
      setDateOfBirth(dayjs(person.dateOfBirth).toDate());
      setNationalIdNumber(
        personIdentifiers.find(
          (identifier) =>
            identifier.identifierTypeName === "National Identification Number"
        )?.identifierValue ?? ""
      );

      setHealthIdNumber(
        personIdentifiers.find(
          (identifier) =>
            identifier.identifierTypeName === "Health Identification Number"
        )?.identifierValue ?? ""
      );

      if (addresses && addresses[0]) {
        setAddressLine(addresses[0].addressLine1);
        if (addresses[0].divisionCode) {
          const division = divisions.find(
            (division) =>
              division.divisionCode === parseInt(addresses[0].divisionCode)
          );
          if (division) {
            setDivision({
              label: division.divisionName,
              value: division.divisionCode,
              code: division.divisionCode
            });
            const district = division.districts.find(
              (district) =>
                district.districtCode === parseInt(addresses[0].districtCode)
            );
            if (district) {
              setDistrict({
                label: district.districtName,
                value: district.districtCode,
                code: district.districtCode
              });

              const subdistrict = district.subdistricts.find(
                (subdistrict) =>
                  subdistrict.subdistrictCode ===
                  parseInt(addresses[0].subdistrictCode)
              );

              if (subdistrict) {
                setSubdistrict({
                  label: subdistrict.subdistrictName,
                  value: subdistrict.subdistrictCode,
                  code: subdistrict.subdistrictCode
                });
              }
            }
          }
        }
      }
    } else if (
      getPatientInformationFromNidResult.isSuccess &&
      !getPatientInformationFromNidResult.data.patient
    ) {
      toast({
        title: "Error",
        description: "NID and Date of Birth do not match any records.",
        status: "error",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
  }, [getPatientInformationFromNidResult]);
  const handleVerification = () => {
    if (nationalIdNumber) {
      getPatientInformationFromNid({
        idType: "nid",
        idValue: nationalIdNumber,
        dateOfBirth: dayjs(dateOfBirth).format("YYYY-MM-DD"),
        name: name === "" || !name ? "not provided" : name
      });
    }
    if (birthCertificate) {
      getPatientInformationFromNid({
        idType: "brn",
        idValue: birthCertificate,
        dateOfBirth: dayjs(dateOfBirth).format("YYYY-MM-DD"),
        name: name === "" || !name ? "not provided" : name
      });
    }
  };
  const handlePatientRegistration = () => {
    createPatient({
      data: {
        otp: patientState.otp || "",
        contactNumber: patientState.phoneNumber || "",
        person: {
          name,
          contactNumber: props.phoneNumber,
          dateOfBirth,
          fatherName: null,
          motherName: null,
          imageUrl: null,
          isDateOfBirthEstimated,
          gender: gender?.value as "male" | "female",
          dateOfDeath: null,
          isDateOfDeathEstimated: null
        },
        patient: {
          patientCategoryName: "General"
        },
        identifiers: [
          ...(religion
            ? [
                {
                  identifierTypeName: "Religion",
                  identifierValue: religion.label
                }
              ]
            : []),
          ...(nationality
            ? [
                {
                  identifierTypeName: "Nationality",
                  identifierValue: nationality
                }
              ]
            : []),
          ...(healthIdNumber
            ? [
                {
                  identifierTypeName: "Health Identification Number",
                  identifierValue: healthIdNumber
                }
              ]
            : []),
          ...(birthCertificate
            ? [
                {
                  identifierTypeName: "Birth Certificate Number",
                  identifierValue: birthCertificate
                }
              ]
            : []),
          ...(nationalIdNumber
            ? [
                {
                  identifierTypeName: "National Identification Number",
                  identifierValue: nationalIdNumber
                }
              ]
            : []),
          ...(occupation
            ? [
                {
                  identifierTypeName: "Occupation",
                  identifierValue: occupation.value
                }
              ]
            : []),
          ...(passportNumber
            ? [
                {
                  identifierTypeName: "Passport Number",
                  identifierValue: passportNumber
                }
              ]
            : [])
        ],
        address: {
          addressLine1: addressLine ? addressLine : null,
          addressLine2: null,
          postalCode: null,
          divisionCode: division ? division.code : null,
          districtCode: district ? district.code : null,
          subdistrictCode: subdistrict ? subdistrict.code : null,
          unionCode: union ? union.code : null,
          addressTypeName: "Present Address"
        }
      }
    });
  };

  useEffect(() => {
    if (createPatientResult.isSuccess) {
      toast({
        title: "Success",
        description: "Patient registered successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });

      dispatch(
        registerNewPatient({
          patientWithDetails: createPatientResult.data.patientWithDetails
        })
      );
      onClose();
    }
  }, [createPatientResult.isSuccess]);

  // If age is edited. change the date of birth
  useEffect(() => {
    if (age) {
      if (age !== dayjs().diff(dayjs(dateOfBirth), "year")) {
        setDateOfBirth(dayjs().subtract(age, "year").toDate());
      }
    }
  }, [age]);

  // If date of birth is updated, update the age accordingly (only year)
  useEffect(() => {
    if (dateOfBirth) {
      if (age !== dayjs().diff(dayjs(dateOfBirth), "year")) {
        setAge(dayjs().diff(dayjs(dateOfBirth), "year"));
      }
    }
  }, [dateOfBirth]);
  useEffect(() => {
    if (division) {
      const selectedDivision = divisions.find(
        (d) => d.divisionCode === division.code
      );
      if (selectedDivision) {
        setDistrictOptions(
          selectedDivision.districts.map((district, index) => ({
            label: district.districtName,
            code: district.districtCode,
            value: index
          }))
        );
      }
    }
  }, [division]);

  useEffect(() => {
    if (district && division) {
      const selectedDivision = divisions.find(
        (d) => d.divisionCode === division.code
      );
      const selectedDistrict = selectedDivision?.districts.find(
        (d) => d.districtCode === district.code
      );
      if (selectedDistrict) {
        setSubdistrictOptions(
          selectedDistrict.subdistricts.map((subdistrict, index) => ({
            label: subdistrict.subdistrictName,
            code: subdistrict.subdistrictCode,
            value: index
          }))
        );
      }
    }
  }, [district, division]);

  useEffect(() => {
    if (subdistrict && district && division) {
      const selectedDivision = divisions.find(
        (d) => d.divisionCode === division.code
      );
      const selectedDistrict = selectedDivision?.districts.find(
        (d) => d.districtCode === district.code
      );
      const selectedSubdistrict = selectedDistrict?.subdistricts.find(
        (s) => s.subdistrictCode === subdistrict.code
      );
      if (selectedSubdistrict) {
        setUnionOptions(
          selectedSubdistrict.unions.map((union, index) => ({
            label: union.unionName,
            code: union.unionCode,
            value: index
          }))
        );
      }
    }
  }, [subdistrict, district, division]);

  const [districtOptions, setDistrictOptions] = useState<
    { label: string; code: number; value: number }[]
  >([]);

  const [subdistrictOptions, setSubdistrictOptions] = useState<
    { label: string; code: number; value: number }[]
  >([]);

  const [unionOptions, setUnionOptions] = useState<
    { label: string; code: number; value: number }[]
  >([]);

  return (
    <>
      <Button
        colorScheme={"blue"}
        leftIcon={<FaPlus />}
        onClick={onOpen}
        visibility={env.isAppointment === "true" ? "hidden" : "visible"}
      >
        Register New Patient (Phone: {props.phoneNumber})
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: "lg", lg: "5xl" }}
        closeOnOverlayClick={false}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Patient Registration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="4" divider={<StackDivider />}>
              <Stack
                spacing="4"
                border="1px"
                borderColor="gray.200"
                borderRadius="md"
                padding="4"
              >
                <Heading fontSize="md">Fetch Health ID</Heading>
                <Text fontSize="sm">
                  সঠিক জন্মতারিখ এবং জাতীয় পরিচয়পত্র/জন্ম নিবন্ধন নম্বর দিয়ে
                  তথ্য অনুসন্ধান করুন
                </Text>
                <SimpleGrid
                  columns={{ base: 1, xl: 2 }}
                  rowGap={3}
                  columnGap={3}
                >
                  <FormControl isInvalid={!isNidValid()}>
                    <FormLabel>National Identification (NID) Number</FormLabel>

                    <Input
                      placeholder="Enter NID Number (Optional)"
                      value={nationalIdNumber}
                      onChange={(event) => {
                        setNationalIdNumber(event.target.value);
                      }}
                    />

                    <FormErrorMessage>
                      Please enter a valid NID number with 10, 13, or 17 digits.
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Birth Certificate Number</FormLabel>
                    <Input
                      placeholder="Enter Birth Certificate (Optional)"
                      value={birthCertificate}
                      onChange={(event) => {
                        setBirthCertificate(event.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Date Of Birth</FormLabel>
                    <DatePicker
                      selected={dateOfBirth}
                      onChange={(date) => date && setDateOfBirth(date)}
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={new Date()}
                    />
                  </FormControl>
                  <Tooltip
                    label={
                      "সঠিক জন্মতারিখ এবং জাতীয় পরিচয়পত্র/জন্ম নিবন্ধন নম্বর দিয়ে তথ্য অনুসন্ধান করুন"
                    }
                    placement="top"
                  >
                    <Button
                      mt={8}
                      colorScheme={"green"}
                      onClick={handleVerification}
                      isLoading={getPatientInformationFromNidResult.isLoading}
                      isDisabled={
                        (!nationalIdNumber && !birthCertificate) ||
                        !isNidValid()
                      }
                    >
                      Fetch Info
                    </Button>
                  </Tooltip>
                </SimpleGrid>
              </Stack>

              <Stack spacing="4">
                <Heading fontSize="md">Patient Details</Heading>
                <SimpleGrid
                  columns={{ base: 1, xl: 2 }}
                  rowGap={3}
                  columnGap={3}
                >
                  <FormControl isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input
                      value={name}
                      onChange={(event) => {
                        setName(event.target.value.toUpperCase());
                      }}
                    />
                  </FormControl>
                  <FormControl isRequired isDisabled>
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                      value={props.phoneNumber}
                      onChange={(event) => props.phoneNumber}
                    />
                  </FormControl>
                </SimpleGrid>

                <SimpleGrid
                  columns={{ base: 1, xl: 2 }}
                  rowGap={3}
                  columnGap={3}
                >
                  <FormControl isRequired>
                    <FormLabel>Gender</FormLabel>
                    <Select
                      selectedOptionStyle="check"
                      options={genderList}
                      value={gender}
                      onChange={(event) => {
                        if (event) {
                          setGender({
                            label: event.label,
                            value: event.value
                          });
                        }
                      }}
                    />
                  </FormControl>
                </SimpleGrid>
              </Stack>
              <Stack spacing="4">
                <Heading fontSize="md">Identification</Heading>
                <SimpleGrid
                  columns={{ base: 1, xl: 2 }}
                  rowGap={2}
                  columnGap={2}
                >
                  <FormControl>
                    <FormLabel>Religion</FormLabel>
                    <Select
                      selectedOptionStyle="check"
                      placeholder="Select Religion (Optional)"
                      options={religionList}
                      value={religion}
                      onChange={(event) => {
                        if (event) {
                          setReligion({
                            label: event.label,
                            value: event.label
                          });
                        }
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Occupation</FormLabel>
                    <Select
                      selectedOptionStyle="check"
                      placeholder="Select Occupation (Optional)"
                      options={occupationList}
                      value={occupation}
                      onChange={(event) => {
                        if (event) {
                          setOccupation({
                            label: event.label,
                            value: event.label
                          });
                        }
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Health Identification Number</FormLabel>
                    <Input
                      placeholder="Enter Health ID (Optional)"
                      value={healthIdNumber}
                      isReadOnly
                      onChange={(event) => {
                        setHealthIdNumber(event.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Nationality</FormLabel>
                    <Input
                      placeholder="Enter Nationality (Optional)"
                      value={nationality}
                      onChange={(event) => {
                        setNationality(event.target.value);
                      }}
                    />
                  </FormControl>
                </SimpleGrid>
              </Stack>

              <Stack spacing="4" pb={12}>
                <Heading fontSize="md">Patient Address</Heading>
                <FormControl>
                  <FormLabel>Address Line</FormLabel>
                  <Input
                    placeholder="Enter Address (Optional)"
                    value={addressLine}
                    onChange={(event) => {
                      setAddressLine(event.target.value);
                    }}
                  />
                </FormControl>
                <SimpleGrid
                  columns={{ base: 1, xl: 4 }}
                  rowGap={2}
                  columnGap={2}
                >
                  <FormControl>
                    <FormLabel>Division</FormLabel>
                    <Select
                      menuPlacement="top"
                      placeholder="Division (Optional)"
                      selectedOptionStyle="check"
                      options={divisions.map((division, index) => {
                        return {
                          label: division.divisionName,
                          code: division.divisionCode,
                          value: index
                        };
                      })}
                      value={division}
                      onChange={(event) => {
                        if (event) {
                          setDivision({
                            label: event.label,
                            code: event.code,
                            value: event.value
                          });
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>District</FormLabel>
                    <Select
                      menuPlacement="top"
                      placeholder="District (Optional)"
                      selectedOptionStyle="check"
                      options={districtOptions}
                      value={district}
                      onChange={(event) => {
                        if (event) {
                          setDistrict({
                            label: event.label,
                            code: event.code,
                            value: event.value
                          });
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Upazilla</FormLabel>
                    <Select
                      menuPlacement="top"
                      placeholder="Upazilla (Optional)"
                      selectedOptionStyle="check"
                      options={subdistrictOptions}
                      value={subdistrict}
                      onChange={(event) => {
                        if (event) {
                          setSubdistrict({
                            label: event.label,
                            code: event.code,
                            value: event.value
                          });
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Union</FormLabel>
                    <Select
                      menuPlacement="top"
                      placeholder="Union (Optional)"
                      selectedOptionStyle="check"
                      options={unionOptions}
                      value={union}
                      onChange={(event) => {
                        if (event) {
                          setUnion({
                            label: event.label,
                            code: event.code,
                            value: event.value
                          });
                        }
                      }}
                    />
                  </FormControl>
                </SimpleGrid>
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              leftIcon={<FaTimes />}
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              leftIcon={<FaPlus />}
              isLoading={createPatientResult.isLoading}
              onClick={handlePatientRegistration}
              isDisabled={!name || !gender || !dateOfBirth}
            >
              Register
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
