import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  useDisclosure,
  FormControl,
  Input,
  FormLabel,
  useToast,
  IconButton,
  Tooltip,
  Text,
  HStack,
  Spacer,
  Checkbox,
  Link,
  Spinner,
  RadioGroup,
  Radio,
  Heading,
  ButtonGroup,
  Divider,
  Flex
} from "@chakra-ui/react";
import { Location as LocationType, PatientWithDetails } from "app/api/type";
import dayjs from "dayjs";
import {
  useBookOnlineAppointmentMutation,
  useGetAllDepartmentsQuery,
  useGetHospitalQuery,
  useSendAppointmentSmsMutation,
  useStartPaymentMutation
} from "../api";
import { Select } from "chakra-react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/chakra-react-datepicker.css";
import { useTypedSelector } from "hooks/use-typed-selector.hook";
import { FaInfoCircle, FaTicketAlt } from "react-icons/fa";
import { env } from "app/config";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useNavigate } from "react-router-dom";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import { PrintableOpdTicketComponent } from "./printable-opd-ticket.component";

interface BookTicketModalType2ComponentProps {
  patientWithDetails: PatientWithDetails;
}

export const BookTicketModalType2Component: React.FC<
  BookTicketModalType2ComponentProps
> = (props: BookTicketModalType2ComponentProps) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSecondModalOpen,
    onOpen: openSecondModal,
    onClose: closeSecondModal
  } = useDisclosure();

  const toast = useToast();
  const [sendAppointmentSms, sendAppointmentSmsResult] =
    useSendAppointmentSmsMutation();
  const getHospitalResult = useGetHospitalQuery({});

  const patientState = useTypedSelector((state) => state.patient);
  const getAllDepartmentsResult = useGetAllDepartmentsQuery({});
  const [bookTicket, bookTicketResult] = useBookOnlineAppointmentMutation();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<{
    label: string;
    value: number;
  }>();

  const [patientPhoneNumber, setPatientPhoneNumber] = useState<string>(
    props.patientWithDetails.person.contactNumber || ""
  );

  const timeSlotsList = [
    {
      label: "08:00 AM - 9:00 AM",
      value: 0
    },
    {
      label: "09:00 AM - 10:00 AM",
      value: 1
    },
    {
      label: "10:00 AM - 11:00 AM",
      value: 2
    },
    {
      label: "11:00 AM - 12:00 PM",
      value: 3
    },
    {
      label: "12:00 PM - 01:00 PM",
      value: 4
    },
    {
      label: "01:00 PM - 02:00 PM",
      value: 5
    }
  ];

  const [isTrue, setIsTrue] = useState<boolean>(false);
  const [departmentList, setDepartmentList] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const [selectedDepartment, setSelectedDepartment] = useState<{
    value: string;
    label: string;
  }>();

  const [bookingDate, setBookingDate] = useState<Date | undefined>();

  const filterDate = (date: Date) => {
    const day = date.getDay();
    return day !== 5;
  };

  useEffect(() => {
    if (!isTrue) {
      const medicalInstructionRoom = departmentList.find(
        (dept) => dept.label === "Medical Instruction Room"
      );
      setSelectedDepartment(medicalInstructionRoom || departmentList[0]);
    }
  }, [isTrue, departmentList]);

  useEffect(() => {
    if (getAllDepartmentsResult.data) {
      setDepartmentList(
        getAllDepartmentsResult.data.locations.map((location) => {
          return {
            label: location.displayName,
            value: location.id
          };
        })
      );
    }
  }, [getAllDepartmentsResult]);

  const handleBookTicket = () => {
    if (bookingDate) {
      bookTicket({
        data: {
          otp: patientState.otp!,
          contactNumber: patientState.phoneNumber!,
          ticket: {
            patientId: props.patientWithDetails.patient.id,
            sourceLocationId: env.onlineLocationId,
            assignedLocationId: selectedDepartment
              ? selectedDepartment.value
              : "",
            ticketCategoryName: "Free",
            bookedFor: bookingDate,
            timeSlotDate: bookingDate,
            timeSlotLocationId: selectedDepartment
              ? selectedDepartment.value
              : "",
            timeSlotSerial: selectedTimeSlot ? selectedTimeSlot.value : 0,
            userId: null
          }
        }
      });
    }
  };

  useEffect(() => {
    if (bookTicketResult.isSuccess) {
      toast({
        title: "Appointment Booked Successfully",
        description: "Please check your details below",
        position: "top",
        status: "success",
        duration: 5000,
        isClosable: true
      });
      onClose();
      openSecondModal();
    }
  }, [bookTicketResult]);

  useEffect(() => {
    if (
      bookTicketResult.isSuccess &&
      bookTicketResult.data &&
      selectedTimeSlot
    ) {
      sendAppointmentSms({
        data: {
          phoneNumber: patientPhoneNumber,
          assignedLocationName:
            bookTicketResult.data?.ticketWithDetails.assignedLocation.name,
          bookingDate: dayjs(
            bookTicketResult.data?.ticketWithDetails.ticket.bookedFor
          ).format("DD-MM-YYYY"),
          slot: selectedTimeSlot.label,
          serial:
            bookTicketResult.data?.ticketWithDetails.ticket.serial?.toString() ||
            ""
        }
      });
    }
  }, [bookTicketResult]);

  const InformationRow = (informationRowProps: {
    label: string;
    value: string;
  }) => {
    return (
      <Flex justifyContent={"space-between"}>
        <Text fontWeight="bold">{informationRowProps.label}</Text>
        <Text>{informationRowProps.value}</Text>
      </Flex>
    );
  };
  return (
    <>
      <Button
        colorScheme={"blue"}
        onClick={onOpen}
        leftIcon={<FaTicketAlt />}
        fontSize={"lg"}
      >
        Book Appointment
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setBookingDate(undefined);
        }}
        isCentered
        size={{ base: "lg", lg: "5xl" }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Book Appointment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="4">
              <FormControl isReadOnly>
                <FormLabel>Patient ID</FormLabel>
                <Input value={props.patientWithDetails.patient.id} />
              </FormControl>

              <FormControl isReadOnly>
                <FormLabel>Patient Name</FormLabel>
                <Input
                  value={
                    props.patientWithDetails.person.name
                      ? props.patientWithDetails.person.name
                      : "Unknown"
                  }
                />
              </FormControl>

              <RadioGroup defaultValue="2">
                <HStack gap="6">
                  <Text>Do you know which department to visit?</Text>
                  <Radio
                    value="1"
                    onChange={() => {
                      setIsTrue(true);
                      setSelectedDepartment(undefined);
                    }}
                  >
                    Yes
                  </Radio>
                  <Radio
                    value="2"
                    onChange={() => {
                      setIsTrue(false);
                    }}
                  >
                    No
                  </Radio>
                </HStack>
              </RadioGroup>

              <FormControl id="department" isRequired>
                <FormLabel>Department</FormLabel>
                {isTrue ? (
                  <Select
                    menuPlacement="top"
                    selectedOptionStyle="check"
                    options={departmentList}
                    value={selectedDepartment}
                    onChange={(event) => {
                      if (event) {
                        setSelectedDepartment({
                          label: event.label,
                          value: event.value
                        });
                      }
                    }}
                  />
                ) : (
                  <Input
                    placeholder="Department"
                    value={selectedDepartment?.label || ""}
                    readOnly
                  />
                )}
              </FormControl>

              <FormControl id="timeSlot" isRequired>
                <FormLabel>Time Slot</FormLabel>
                <Select
                  menuPlacement="top"
                  selectedOptionStyle="check"
                  options={timeSlotsList}
                  value={selectedTimeSlot}
                  onChange={(event) => {
                    if (event) {
                      setSelectedTimeSlot({
                        label: event.label,
                        value: event.value
                      });
                    }
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontWeight={"bold"}>
                  Patient Phone Number (For Confirmation SMS)
                </FormLabel>
                <Input
                  placeholder="Enter the phone number to receive confirmation SMS."
                  value={patientPhoneNumber}
                  onChange={(event) =>
                    setPatientPhoneNumber(event.target.value)
                  }
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Booking Date</FormLabel>
                <DatePicker
                  selected={bookingDate}
                  filterDate={filterDate}
                  onChange={(date) => date && setBookingDate(date)}
                  dateFormat="dd-MM-yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  // minDate should be tomorrow if 12 PM has passed in GMT+6
                  minDate={
                    dayjs().tz("Asia/Dhaka").hour() >= 11
                      ? dayjs().tz("Asia/Dhaka").add(1, "day").day() === 5
                        ? dayjs().tz("Asia/Dhaka").add(2, "day").toDate()
                        : dayjs().tz("Asia/Dhaka").add(1, "day").toDate()
                      : dayjs().tz("Asia/Dhaka").toDate()
                  }
                  excludeDates={[dayjs(env.excludedDate).toDate()]}
                />
              </FormControl>

              <Checkbox defaultChecked>
                <Text as="span">
                  I agree to the{" "}
                  <Link
                    href="/terms-and-conditions"
                    color="blue.500"
                    isExternal
                  >
                    Terms & Conditions
                  </Link>{" "}
                  and{" "}
                  <Link
                    href="/terms-and-conditions"
                    color="blue.500"
                    isExternal
                  >
                    Refund Policy
                  </Link>
                </Text>
              </Checkbox>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4} justifyContent="space-between" w="100%">
              <Spacer />
              <Button
                colorScheme="red"
                mr={3}
                onClick={() => {
                  onClose();
                  setBookingDate(undefined);
                }}
              >
                Cancel
              </Button>
              <Button
                isDisabled={!bookingDate || !selectedDepartment}
                colorScheme="blue"
                onClick={handleBookTicket}
                isLoading={bookTicketResult.isLoading}
              >
                Book Appointment
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isSecondModalOpen}
        onClose={closeSecondModal}
        isCentered
        size={{ base: "lg", lg: "xl" }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Appointemnt Booking Successful!</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={"70px"} mt="4">
            <Stack spacing="4">
              <Stack
                width={{ base: "95%", md: "90%", lg: "100%" }}
                spacing={4}
                border="1px"
                borderColor="gray.300"
                borderRadius="md"
                p={4}
                textAlign="left"
              >
                <InformationRow
                  label="Issue Time:"
                  value={dayjs(
                    bookTicketResult.data?.ticketWithDetails.ticket.issueTime
                  ).format("hh:mm a, MMM DD, YYYY")}
                />
                <InformationRow
                  label="Booked For:"
                  value={dayjs(
                    bookTicketResult.data?.ticketWithDetails.ticket.bookedFor
                  ).format("MMM DD, YYYY")}
                />
                <InformationRow
                  label="Slot:"
                  value={selectedTimeSlot?.label || "-"}
                />
                <InformationRow
                  label="Serial:"
                  value={
                    bookTicketResult.data?.ticketWithDetails.ticket.serial?.toString() ||
                    "-"
                  }
                />
                <InformationRow
                  label="Assigned Department:"
                  value={
                    bookTicketResult.data?.ticketWithDetails.assignedLocation
                      .name || "-"
                  }
                />
                <InformationRow
                  label="Patient ID:"
                  value={
                    bookTicketResult.data?.patientWithDetails.patient.id || "-"
                  }
                />
                <InformationRow
                  label="Patient Name:"
                  value={
                    bookTicketResult.data?.patientWithDetails.person.name || "-"
                  }
                />
                <InformationRow
                  label="Symptom:"
                  value={
                    bookTicketResult.data?.ticketWithDetails.ticket.symptom ||
                    "-"
                  }
                />

                <Stack
                  direction="column"
                  width="100%"
                  alignItems={"center"}
                  justify="center"
                >
                  {bookTicketResult.data &&
                  bookTicketResult.data.ticketWithDetails.ticket.isPaid &&
                  getHospitalResult.data ? (
                    <PDFDownloadLink
                      document={
                        <PrintableOpdTicketComponent
                          ticketWithDetails={
                            bookTicketResult.data.ticketWithDetails
                          }
                          patientWithDetails={
                            bookTicketResult.data.patientWithDetails
                          }
                          personIdentifiers={[
                            bookTicketResult.data.patientWithDetails
                              .personIdentifiers
                          ]}
                          hospitalLocation={getHospitalResult.data.location}
                          printingLocation={
                            bookTicketResult.data.ticketWithDetails
                              .sourceLocation
                          }
                          printerPerson={
                            bookTicketResult.data.patientWithDetails.person
                          }
                        />
                      }
                      fileName={`${bookTicketResult.data.ticketWithDetails.ticket.id}.pdf`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button
                            colorScheme="blue"
                            width="100%"
                            isLoading
                            isDisabled
                          >
                            Loading...
                          </Button>
                        ) : (
                          <Button colorScheme="blue" width="100%">
                            Print Appointment Slip
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : (
                    <Button colorScheme="blue" isDisabled>
                      Print Appointment Slip
                    </Button>
                  )}
                </Stack>
              </Stack>

              <Divider />
            </Stack>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
