import { PatientWithDetails } from "app/api/type";
import { env } from "app/config";
import React from "react";
import { BookTicketModalType1Component } from "./book-ticket-modal-type-1.component";
import { BookTicketModalType2Component } from "./book-ticket-modal-type-2.component";

interface BookTicketModalComponentProps {
  patientWithDetails: PatientWithDetails;
}

export const BookTicketModalComponent: React.FC<
  BookTicketModalComponentProps
> = (props: BookTicketModalComponentProps) => {
  // Check if `env.isAppointment` is "true" and return the corresponding component
  return env.isAppointment === "true" ? (
    <BookTicketModalType2Component
      patientWithDetails={props.patientWithDetails}
    />
  ) : (
    <BookTicketModalType1Component
      patientWithDetails={props.patientWithDetails}
    />
  );
};
